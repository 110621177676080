<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.costo-horario.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.costo-horario.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.costo-horario.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-row>
              <v-col>
                <v-select
                  v-model="filters.selected.items"
                  :items="filters.data.items"
                  item-text="nombre"
                  item-value="id"
                  :label="$t('app.sucursales.name')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  outlined
                  rounded
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="filters.selected.query"
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="10"
            :search="filters.selected.query"
          >
            <template v-slot:item.dia="{ item }">
              {{ diaDeLaSemana(item.dia) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <costo-horario-add />
    <costo-horario-edit />
    <costo-horario-detail />
    <costo-horario-delete />
  </v-container>
</template>

<script>
  export default {
    name: 'CostoHorario',
    components: {
      CostoHorarioAdd: () => import('./CostoHorarioAdd'),
      CostoHorarioEdit: () => import('./CostoHorarioEdit'),
      CostoHorarioDetail: () => import('./CostoHorarioDetail'),
      CostoHorarioDelete: () => import('./CostoHorarioDelete'),
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.deportes.single'),
            value: 'deporte.descripcion'
          },
          {
            text: this.$t('app.headers.day'),
            value: 'dia',
          },
          {
            text: this.$t('app.headers.time'),
            value: 'hora',
          },
          {
            text: this.$t('app.headers.price'),
            value: 'costo',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          data: {
            items: [],
          },
          selected: {
            items: [],
            query: '',
          },
        },
      }
    },
    computed: {
      filteredItems () {
        if (this.filters.selected.items.length > 0) {
          return this.items.filter(item => this.filters.selected.items.includes(item.sucursal_id))
        } else {
          return this.items
        }
      },
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      async init () {
        this.toggleLoader()

        this.filters.data.items = await this.getBranches()
        this.items = await this.getCostoHorarios()

        this.toggleLoader()
      },
      openItemAdd () {
        EventBus.$emit('costo-horario-add')
      },
      openItemEdit (item) {
        EventBus.$emit('costo-horario-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('costo-horario-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('costo-horario-delete', item)
      },
      diaDeLaSemana(dia) {
        return this.$moment().day(dia).format('dddd')
      },
    },
  }
</script>

<style scoped>

</style>
